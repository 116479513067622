<script lang="ts" setup generic="T extends { [key: string]: any }">
import { useI18n } from 'vue-i18n';

export type Header = { label: string; key: string; align?: string; className?: string; sortable?: string };
export interface Filter {
  name: string;
  type: 'asc' | 'desc';
}

const emit = defineEmits(['pageChange', 'click-row', 'update-filter']);
const props = withDefaults(
  defineProps<{
    headers: Header[];
    items: T[];
    loading?: boolean;
    error?: string | null;
    itemsPerPage?: number;
    filter?: Filter | null;
    clickable?: boolean;
  }>(),
  {
    itemsPerPage: 8,
  },
);

const { t } = useI18n();

const numberOfPages = computed(() => Math.ceil(props.items.length / 8));

const goToPage = (page: number) => {
  currentPage.value = page;
  emit('pageChange', page);
};

const currentPage = ref(1);

const goToNextPage = () => {
  if (numberOfPages.value > 1) {
    if (currentPage.value < numberOfPages.value) {
      goToPage(currentPage.value + 1);
    }
  }
};

const goToPreviousPage = () => {
  if (numberOfPages.value > 1) {
    if (currentPage.value > 1) {
      goToPage(currentPage.value - 1);
    }
  }
};

const sortColumn = (header: Header) => {
  if (!header.sortable) return;
  if (props.filter?.type === 'asc') {
    emit('update-filter', null);
  } else if (props.filter?.type === 'desc') {
    emit('update-filter', { name: header.sortable, type: 'asc' });
  } else if (props.filter === null) {
    emit('update-filter', { name: header.sortable, type: 'desc' });
  }
};

watch(
  () => props.items,
  () => {
    if (numberOfPages.value < 2) {
      currentPage.value = 1;
    }
  },
);
</script>
<template>
  <div class="flow-root">
    <div class="max-md:overflow-x-auto overflow-visible">
      <div class="inline-block w-full align-middle">
        <div class="relative overflow-x-auto border rounded-lg">
          <table class="min-w-full divide-gray-200">
            <thead class="bg-gray-50 relative border-b border-gray-200">
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="`table-header-${index}`"
                  scope="col"
                  class="py-2.5 px-4 whitespace-nowrap text-xs text-left font-normal text-gray-500 uppercase"
                  :class="[header.align === 'center' ? 'flex items-center justify-center' : '', header.className]"
                >
                  <div class="flex items-center" :class="clickable ? 'cursor-pointer' : ''">
                    <slot :name="`header-${header.key}`">
                      {{ header.label }}
                    </slot>

                    <ui-icon
                      v-if="filter?.name === header.sortable && filter?.type === 'asc'"
                      name="ChevronUp"
                      class="w-4 ml-2"
                      @click="sortColumn(header)"
                    />
                    <ui-icon
                      v-else-if="filter?.name === header.sortable && filter?.type === 'desc'"
                      class="w-4 ml-2"
                      name="ChevronDown"
                      @click="sortColumn(header)"
                    />
                    <ui-icon v-else-if="header.sortable" class="w-4 ml-2" name="ChevronsUpDown" @click="sortColumn(header)" />
                  </div>
                </th>
              </tr>
            </thead>

            <!-- Table Data -->
            <tbody v-if="items?.length !== 0 && !error" class="divide-y divide-gray-200 bg-white relative">
              <div
                v-if="loading"
                class="top-0 z-10 left-0 right-0 bottom-0 absolute bg-white bg-opacity-15 backdrop-blur-[2px] flex items-center justify-center"
              >
                <ui-loader class="w-8 h-8" color="primary" />
              </div>
              <tr
                v-for="(item, index) in items?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)"
                :key="`table-row-${index}`"
                class="hover:bg-gray-50"
                :class="clickable ? 'cursor-pointer' : ''"
              >
                <td
                  v-for="(header, colIndex) in headers"
                  :key="`table-body-${header.key}`"
                  class="whitespace-nowrap py-2.5 text-sm font-medium text-gray-600 pl-4"
                  :class="[
                    colIndex === 0 ? 'pl-4 pr-3' : 'px-3',
                    header.align === 'center' ? 'flex items-center justify-center space-x-1' : '',
                  ]"
                  @click="header.key !== 'action' && header.key !== 'actions' ? $emit('click-row', item) : ''"
                >
                  <slot :name="`item-${header.key}`" :item="item" :index="index">
                    {{ item[header.key] ?? '--' }}
                  </slot>
                </td>
              </tr>
            </tbody>

            <!-- When Loading  -->
            <tbody v-else-if="loading && !error && items?.length === 0" class="divide-y divide-gray-200 bg-white">
              <tr v-for="(item, index) in 10" :key="`table-row-loading-${index}`">
                <td
                  v-for="(header, colIndex) in headers"
                  :key="`table-body-${header.key}`"
                  class="whitespace-nowrap py-4 text-sm font-medium text-gray-600 sm:pl-6"
                  :class="[
                    colIndex === 0 ? 'pl-4 pr-3' : 'px-3',
                    header.align === 'center' ? 'flex items-center justify-center space-x-1' : '',
                  ]"
                >
                  <ui-skeleton-loader class-name="w-full h-[12px] rounded-[8px]" />
                </td>
              </tr>
            </tbody>

            <!-- When Error -->
            <tbody v-else-if="error" class="divide-y divide-gray-200 bg-white">
              <tr>
                <td :colSpan="headers.length" class="whitespace-nowrap py-2 text-sm font-medium text-gray-900 px-2">
                  <div class="w-full flex items-center justify-center">
                    <slot name="error">
                      <ui-error :title="$t('global.data_error')" class="w-[100%]" />
                    </slot>
                  </div>
                </td>
              </tr>
            </tbody>

            <!-- When No data -->
            <tbody v-else-if="items?.length === 0 && !error && !loading">
              <tr>
                <td :colspan="headers.length">
                  <div class="flex justify-center items-center w-full py-10">
                    <div class="text-center">
                      <h3 class="mt-2 text-sm font-semibold text-gray-900">{{ $t('global.no_data') }}</h3>
                      <slot name="no-data-message" class="mt-1 text-sm text-gray-500">
                        <p class="mt-1 text-sm text-gray-500">
                          {{ $t('global.no_data_description') }}
                        </p>
                      </slot>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="props.items.length > itemsPerPage" class="flex items-center justify-between mt-4">
    <div>
      <p class="text-xs text-gray-500">
        {{
          t('global.showing_items', {
            start: props.items.length === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1,
            end: currentPage * itemsPerPage > props.items.length ? props.items.length : currentPage * itemsPerPage,
            total: props.items.length,
          })
        }}
      </p>
    </div>
    <div v-if="props.items.length > itemsPerPage" class="flex gap-2 items-center">
      <ui-button
        color="secondary"
        :disabled="currentPage === 1"
        :class="currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'"
        size="xs"
        @click="goToPreviousPage"
      >
        {{ t('global.previous') }}
      </ui-button>

      <ui-button
        color="secondary"
        :class="currentPage === numberOfPages ? 'cursor-not-allowed' : 'cursor-pointer'"
        :disabled="currentPage === numberOfPages"
        size="xs"
        @click="goToNextPage"
      >
        {{ t('global.next') }}
      </ui-button>
    </div>
  </div>
</template>
